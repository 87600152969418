import BaseModule from './utils/BaseModule'
import { baseconfig } from "@/config.js";
class BaseManager extends BaseModule {
  constructor() {
    super()
  }
  ///获取菜单
  sysMenuTreeList() {
    return this.get('sys/menu/treeList')
  }

  //后台用户列表
  sysUserListv(param) {
    return this.get('sys/user/listv', param)
  }

  //后台新增用户
  sysUserSave(param) {
    return this.post('sys/user/save', param)
  }
  //后台更新用户
  sysUserUpdate(param) {
    return this.post('sys/user/update', param)
  }
  //后台删除用户
  sysUserRemove(param) {
    return this.post('sys/user/remove', param)
  }
  //后台超管修改用户密码
  sysUserAdminResetPwd(param) {
    return this.post('sys/user/adminResetPwd', param)
  }

  // 获取角色列表数据
  sysRoleListv() {
    return this.get('sys/role/listv')
  }
  // 指定权限下的菜单列表
  sysMenuTreeByRole(param) {
    return this.get(`sys/menu/treeByRole/${param}`)
  }
  // 添加角色信息
  sysRoleSave(param) {
    return this.post('sys/role/save', param)
  }
  // 编辑角色信息
  sysRoleUpdate(param) {
    return this.post('sys/role/update', param)
  }
  // 删除角色信息
  sysRoleRemove(param) {
    return this.post('sys/role/remove', param)
  }



  /**
   * 系统菜单
   */
  //获取系统菜单列表
  getsystemmenulist() {
    return this.post('/sys/menu/sys_treeList')
  }

  // POST    添加系统菜单
  addSystemMenuInfo(param) {
    const paras = {
      parentId: param.parentId,
      name: param.name,
      url: param.url,
      perms: param.perms,
      type: param.type,
      icon: param.icon,
      orderNum: param.orderNum
    }
    return this.post('/sys/menu/save', paras)
  }

  // POST    编辑系统菜单
  editSystemMenuInfo(param) {
    const paras = {
      parentId: param.parentId,
      name: param.name,
      url: param.url,
      perms: param.perms,
      type: param.type,
      icon: param.icon,
      orderNum: param.orderNum,
      menuId: param.menuId
    }
    return this.post('/sys/menu/update', paras)
  }

  // POST    删除系统菜单
  delSystemMenuInfo(param) {
    const paras = {
      id: param.id,
    }
    return this.post('/sys/menu/remove', paras)
  }

  // POST    修改密码
  resetPassword(param) {
    return this.post('/sys/user/resetPassword', param)
  }



  // 2024-05-21 接口hzh  
  // 查pc/expert/listv  曾 pc/expert/save  修pc/expert/update 删pc/expert/remove
  // 参数跟用户管理的一样
  //专家列表
  expertList (param) {
    return this.get('/pc/expert/listv', param)
  }
  //新增专家
  expertSave (param) {
    return this.post('/pc/expert/save', param)
  }
  //修改专家
  expertUpdate (param) {
    return this.post('/pc/expert/update', param)
  }
  //删除专家
  expertRemove (param) {
    return this.post('/pc/expert/remove', param)
  }
  //小程序专家列表
  selectUserList (param) {
    return this.get('/pc/expert/expertList', param)
  }


  //2024-05-25 代理商提现申请
  addWithdrawal (param) {
    return this.post('/web/withdrawalRecord/withdrawal', param)
  }



}

export default new BaseManager()