var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login-bg fl-c fr-c" }, [
    _c("div", { staticClass: "lockscreen-wrap fl-c" }, [
      _c(
        "div",
        { staticClass: "ibox-content" },
        [
          _vm._m(0),
          _c(
            "el-form",
            {
              ref: "addOrEditModel",
              staticClass: "ibox-content-form",
              attrs: {
                "label-width": "140px",
                model: _vm.addOrEditModel,
                rules: _vm.editPwdRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "姓名", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      "prefix-icon": "el-icon-user",
                      placeholder: "请输入姓名",
                    },
                    model: {
                      value: _vm.addOrEditModel.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrEditModel, "name", $$v)
                      },
                      expression: "addOrEditModel.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "邮箱", prop: "email" } },
                [
                  _c("el-input", {
                    attrs: {
                      "prefix-icon": "el-icon-message",
                      placeholder: "请输入邮箱",
                    },
                    model: {
                      value: _vm.addOrEditModel.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrEditModel, "email", $$v)
                      },
                      expression: "addOrEditModel.email",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: { label: "身份证正面照片", prop: "idCardFront" },
                },
                [
                  _c("UploadImageNewOne", {
                    attrs: {
                      limit: 1,
                      imgList: _vm.addOrEditModel.idCardFront,
                      accept: "image/*",
                    },
                    on: {
                      "update:imgList": function ($event) {
                        return _vm.$set(
                          _vm.addOrEditModel,
                          "idCardFront",
                          $event
                        )
                      },
                      "update:img-list": function ($event) {
                        return _vm.$set(
                          _vm.addOrEditModel,
                          "idCardFront",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: { label: "身份证反面照片", prop: "idCardBack" },
                },
                [
                  _c("UploadImageNewOne", {
                    attrs: {
                      limit: 1,
                      imgList: _vm.addOrEditModel.idCardBack,
                      accept: "image/*",
                    },
                    on: {
                      "update:imgList": function ($event) {
                        return _vm.$set(
                          _vm.addOrEditModel,
                          "idCardBack",
                          $event
                        )
                      },
                      "update:img-list": function ($event) {
                        return _vm.$set(
                          _vm.addOrEditModel,
                          "idCardBack",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "身份证号", prop: "idCardNo" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入身份证号" },
                    model: {
                      value: _vm.addOrEditModel.idCardNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrEditModel, "idCardNo", $$v)
                      },
                      expression: "addOrEditModel.idCardNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "身份证住址", prop: "idCardAddress" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入身份证住址" },
                    model: {
                      value: _vm.addOrEditModel.idCardAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrEditModel, "idCardAddress", $$v)
                      },
                      expression: "addOrEditModel.idCardAddress",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "身份证有效期开始",
                    prop: "idCardValidityStart",
                  },
                },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      "value-format": "timestamp",
                      type: "date",
                      placeholder: "请选择身份证有效期开始",
                      "picker-options": _vm.pickerOptionsStart,
                    },
                    model: {
                      value: _vm.addOrEditModel.idCardValidityStart,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrEditModel, "idCardValidityStart", $$v)
                      },
                      expression: "addOrEditModel.idCardValidityStart",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "身份证有效期结束",
                    prop: "idCardValidityEnd",
                  },
                },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      "value-format": "timestamp",
                      type: "date",
                      placeholder: "请选择身份证有效期结束",
                      "picker-options": _vm.pickerOptionsEnd,
                    },
                    model: {
                      value: _vm.addOrEditModel.idCardValidityEnd,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrEditModel, "idCardValidityEnd", $$v)
                      },
                      expression: "addOrEditModel.idCardValidityEnd",
                    },
                  }),
                ],
                1
              ),
              _c("el-descriptions", { attrs: { title: "银行卡信息" } }),
              _c(
                "el-form-item",
                { attrs: { label: "开户名称", prop: "accountName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入开户名称" },
                    model: {
                      value: _vm.addOrEditModel.accountName,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrEditModel, "accountName", $$v)
                      },
                      expression: "addOrEditModel.accountName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "银行卡号", prop: "bankCardNumber" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入银行卡号" },
                    model: {
                      value: _vm.addOrEditModel.bankCardNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrEditModel, "bankCardNumber", $$v)
                      },
                      expression: "addOrEditModel.bankCardNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "银行名称", prop: "bankName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入银行名称" },
                    model: {
                      value: _vm.addOrEditModel.bankName,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrEditModel, "bankName", $$v)
                      },
                      expression: "addOrEditModel.bankName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "预留手机号", prop: "reservePhoneNumber" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入预留手机号" },
                    model: {
                      value: _vm.addOrEditModel.reservePhoneNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrEditModel, "reservePhoneNumber", $$v)
                      },
                      expression: "addOrEditModel.reservePhoneNumber",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.clickEditPwdCancel } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "50px" },
                  attrs: { type: "primary", loading: _vm.editPwdLoading },
                  on: { click: _vm.clickEditPwdConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ibox-content-header fr-b-c" }, [
      _c("span", { staticClass: "ibox-content-header-title w-1" }, [
        _vm._v("实名认证"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }