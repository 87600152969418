<template>
    <div class="login-bg fl-c fr-c">
        <div class="lockscreen-wrap fl-c">
            <div class="ibox-content">
                <div class="ibox-content-header fr-b-c">
                    <span class="ibox-content-header-title w-1">实名认证</span>
                    <!-- <el-button class="ibox-content-header-close" type="text" icon="el-icon-close"
                        @click="clickEditPwdCancel"></el-button> -->
                </div>
                <el-form class="ibox-content-form" label-width="140px" ref="addOrEditModel" :model="addOrEditModel"
                    :rules="editPwdRules">
                    <el-form-item label="姓名" prop="name">
                        <el-input v-model="addOrEditModel.name" prefix-icon="el-icon-user"
                            placeholder="请输入姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱" prop="email">
                        <el-input v-model="addOrEditModel.email" prefix-icon="el-icon-message"
                            placeholder="请输入邮箱"></el-input>
                    </el-form-item>
                    <el-form-item label="身份证正面照片" prop="idCardFront" class="is-required">
                        <UploadImageNewOne :limit="1" :imgList.sync="addOrEditModel.idCardFront" accept="image/*">
                        </UploadImageNewOne>
                    </el-form-item>
                    <el-form-item label="身份证反面照片" prop="idCardBack" class="is-required">
                        <UploadImageNewOne :limit="1" :imgList.sync="addOrEditModel.idCardBack" accept="image/*">
                        </UploadImageNewOne>
                    </el-form-item>
                    <el-form-item label="身份证号" prop="idCardNo">
                        <el-input v-model="addOrEditModel.idCardNo" placeholder="请输入身份证号"></el-input>
                    </el-form-item>
                    <el-form-item label="身份证住址" prop="idCardAddress">
                        <el-input v-model="addOrEditModel.idCardAddress" placeholder="请输入身份证住址"></el-input>
                    </el-form-item>
                    <el-form-item label="身份证有效期开始" prop="idCardValidityStart">
                        <el-date-picker value-format="timestamp" style="width: 300px"
                            v-model="addOrEditModel.idCardValidityStart" type="date" placeholder="请选择身份证有效期开始"
                            :picker-options="pickerOptionsStart">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="身份证有效期结束" prop="idCardValidityEnd">
                        <el-date-picker value-format="timestamp" style="width: 300px"
                            v-model="addOrEditModel.idCardValidityEnd" type="date" placeholder="请选择身份证有效期结束"
                            :picker-options="pickerOptionsEnd">
                        </el-date-picker>
                    </el-form-item>


                    <el-descriptions title="银行卡信息"></el-descriptions>
                    <el-form-item label="开户名称" prop="accountName">
                        <el-input v-model="addOrEditModel.accountName" placeholder="请输入开户名称"></el-input>
                    </el-form-item>
                    <el-form-item label="银行卡号" prop="bankCardNumber">
                        <el-input v-model="addOrEditModel.bankCardNumber" placeholder="请输入银行卡号"></el-input>
                    </el-form-item>
                    <el-form-item label="银行名称" prop="bankName">
                        <el-input v-model="addOrEditModel.bankName" placeholder="请输入银行名称"></el-input>
                    </el-form-item>
                    <el-form-item label="预留手机号" prop="reservePhoneNumber">
                        <el-input v-model="addOrEditModel.reservePhoneNumber" placeholder="请输入预留手机号"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer" style="text-align: center;">
                    <el-button @click="clickEditPwdCancel">取 消</el-button>
                    <el-button style="margin-left: 50px" type="primary" :loading="editPwdLoading"
                        @click="clickEditPwdConfirm">确定</el-button>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import systemSetManager from "@/request/systemSetManager.js";
import UploadImageNewOne from "@/components/common/subgroup/UploadImageNewOne.vue"; //上传图片


export default {
    props: ['teacherId'],
    components: {
        UploadImageNewOne,
    },
    data() {
        var validateEmail = (rule, value, callback) => {
            if (value) {
                var regex = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
                if (regex.test(value)) {
                    callback()
                } else {
                    callback(new Error('请输入正确的邮箱'))
                }
            } else {
                callback()
            }
        }
        return {
            pickerOptionsStart: {
                disabledDate: (time) => {
                    if (this.addOrEditModel.idCardValidityEnd != '' && this.addOrEditModel.idCardValidityEnd != null) {
                        // return (
                        //   time.getTime() > Date.now() || time.getTime() > this.query.endTime
                        // )
                        return time.getTime() > this.addOrEditModel.idCardValidityEnd
                    } else {
                        // return time.getTime() > Date.now()
                    }
                },
            },
            pickerOptionsEnd: {
                disabledDate: (time) => {
                    return time.getTime() < this.addOrEditModel.idCardValidityStart
                    // return (
                    //   time.getTime() < this.query.startTime || time.getTime() > Date.now()
                    // )
                },
            },
            addOrEditModel: {
                name: '',
                email: '',
                idCardFront: '',
                idCardBack: '',
                idCardNo: '',
                idCardAddress: '',
                idCardValidityStart: '',
                idCardValidityEnd: '',
                accountName: '',
                bankCardNumber: '',
                bankName: '',
                reservePhoneNumber: '',
            },
            editPwdRules: {
                name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
                email: [{ required: true, message: "请输入邮箱", trigger: "blur" },{ required: false, validator: validateEmail, trigger: 'blur' }],
                idCardFront: [{ required: true, message: "请上传身份证正面照片", trigger: "blur" }],
                idCardBack: [{ required: true, message: "请上传身份证反面照片", trigger: "blur" }],
                idCardNo: [{ required: true, message: "请输入身份证号", trigger: "blur" }],
                idCardAddress: [{ required: true, message: "请输入身份证住址", trigger: "blur" }],
                idCardValidityStart: [{ required: true, message: "请选择身份证有效期开始", trigger: "blur" }],
                idCardValidityEnd: [{ required: true, message: "请选择身份证有效期结束", trigger: "blur" }],
                accountName: [{ required: true, message: "请输入开户名称", trigger: "blur" }],
                bankCardNumber: [{ required: true, message: "请输入银行卡号", trigger: "blur" }],
                bankName: [{ required: true, message: "请输入银行名称", trigger: "blur" }],
                reservePhoneNumber: [{ required: true, message: "请输入预留手机号", trigger: "blur" }],
            },
            editPwdLoading: false
        }
    },
    // 监听属性 类似于data概念
    computed: {

    },
    // 监控data中的数据变化
    watch: {
        isNew(val) {
            console.log(val)
        }
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        console.log(this.isNew)
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    // 方法集合
    methods: {
        // 点击修改密码---取消
        clickEditPwdCancel() {
            this.$emit('hide')
        },
        // 点击修改密码---确认
        async clickEditPwdConfirm() {
            this.$refs.addOrEditModel.validate(async (valid) => {
                if (valid) {
                    try {
                        this.editPwdLoading = true
                        let { code, message } = await systemSetManager.saveRealNameAuthenticationInfo(this.addOrEditModel)
                        if (code === 200) {
                            this.$message.success(message)
                            this.$emit('hide')
                        }
                    } catch (error) {
                        console.log(error)
                    } finally {
                        setTimeout(() => {
                            this.editPwdLoading = false
                        }, 100);
                    }
                }
            })
        },
    },
    // 生命周期 - 创建之前
    beforeCreate() { },
    // 生命周期 - 挂载之前
    beforeMount() { },
    // 生命周期 - 更新之前
    beforeUpdate() { },
    // 生命周期 - 更新之后
    updated() { },
    // 生命周期 - 销毁之前
    beforeDestroy() { },
    // 生命周期 - 销毁完成
    destroyed() { },
    //  // 如果页面有keep-alive缓存功能，这个函数会触发
    activated() { },
}
</script>

<style scoped>
.login-bg {
    background: rgba(0, 0, 0, .3);
    background-size: cover;
    height: 100%;
}

.lockscreen-wrap {
    position: relative;
    height: 100%;
    width: 100%;
}

.ibox-content {
    background-color: #fff;
    /* padding: 25px 50px 30px 50px; */
    clear: both;
    border-radius: 15px;
    width: 600px;
    overflow: hidden;
    max-height: 80%;
    overflow-y: auto;
}

.ibox-content-header {
    height: 45px;
    background-color: #E5E5E6;
    padding: 0px 15px;
}

.ibox-content-header-title {
    font-size: 15px;
    text-align: center;
}

.ibox-content-header-close /deep/ .el-icon-close {
    font-size: 18px;
    color: #323232;
}

.ibox-content-form {
    padding: 20px 50px 0px 10px;
}

.ibox-content-form /deep/ .el-form-item__label {
    line-height: 40px;
}


.full-width {
    width: 100% !important;
}

.dialog-footer {
    padding: 0px 0px 30px;
    box-sizing: border-box;
}

.dialog-footer button {
    width: 100px;
    font-size: 14px;
}
</style>